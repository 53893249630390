import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Logo className="mx-auto h-auto w-56 pt-8 lg:absolute lg:z-10 lg:left-0 lg:ml-12" route="https://candide.berlin/startseite" />
    <div className="px-6 text-center md:flex md:items-center">

      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
